import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import userStore from "@reservauto/react-shared/stores/userStore";
import { jwtDecode } from "jwt-decode";
import { User, UserProfile as OidcUserProfile } from "oidc-client-ts";
import { CustomerDetailDTO } from "../../areas/customer/dto";

export interface UserProfile extends OidcUserProfile {
  [claimKey: string]: unknown;
  branchId: string;
  displayName: string;
  idp: string;
  role: Array<string>;
  ui_locale: string;
  userType: string;
}

export interface UserInfo {
  details: CustomerDetailDTO | null;

  isAuthenticatedViaNewSecurityService: boolean;

  /**
   * @deprecated Not available in app webviews
   */
  profile: UserProfile | null;
}

function isTokenFromNewSecurityService(accessToken: string): boolean {
  return accessToken ? "Duende_UserUniqueId" in jwtDecode(accessToken) : false;
}

export class UserFOStore extends StateStoreBase<UserInfo> {
  private userInfo: UserInfo = {
    details: null,
    isAuthenticatedViaNewSecurityService: false,
    profile: null,
  };

  public get(): UserInfo {
    return this.userInfo;
  }

  public isSignedIn(): boolean {
    return userStore.isSignedIn();
  }

  public populate(authenticatedUser: User): UserProfile {
    const profile = authenticatedUser.profile as UserProfile;

    this.userInfo = {
      ...this.userInfo,
      isAuthenticatedViaNewSecurityService: isTokenFromNewSecurityService(
        authenticatedUser.access_token,
      ),
      profile: profile,
    };
    userStore.populate({
      accessToken: authenticatedUser.access_token,
      uniqueUserId: `${profile.sub}_${profile.branchId}`,
    });

    this.notifySubscribers();

    return profile;
  }

  public async populateDetails(
    getDetails: () => Promise<CustomerDetailDTO>,
  ): Promise<void> {
    const details = await getDetails();

    this.userInfo = {
      ...this.userInfo,
      details: details,
    };
    if (!userStore.get().uniqueUserId) {
      userStore.populate({ uniqueUserId: details.customerId.toString() });
    }

    this.notifySubscribers();
  }

  public setAccessToken(accessToken: string): void {
    this.userInfo = {
      ...this.userInfo,
      isAuthenticatedViaNewSecurityService:
        isTokenFromNewSecurityService(accessToken),
    };

    userStore.populate({ accessToken: accessToken });

    this.notifySubscribers();
  }
}

const userFOStore = new UserFOStore();
export default userFOStore;
